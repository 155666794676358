import "./App.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppLayout from "./layout/AppLayout";

const theme = createTheme();

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppLayout />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
