import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { clientId } from "./config";
// refresh token
import { refreshTokenSetup } from "./utils/refreshToken";
import { selectLoggedIn, login } from "./redux/slice";
import { useAppSelector, useAppDispatch } from "src/redux/hooks";

function instanceOfGoogleLoginResponse(data: any): data is GoogleLoginResponse {
  return "googleId" in data;
}

function GoogleSignIn() {
  const isLoggedIn = useAppSelector(selectLoggedIn);
  const dispatch = useAppDispatch();

  const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (instanceOfGoogleLoginResponse(res)) {
      dispatch(
        login({
          Name: "google",
          GoogleLoginResponse: res,
        })
      );
    } else {
      console.log("Login Success: offline code:", res.code);
    }
    refreshTokenSetup(res);
  };

  const onFailure = (err: any) => {
    console.log("Login failed: res:", err);
  };

  return (
    <div>
      <GoogleLogin
        clientId={clientId}
        buttonText="Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        style={{ marginTop: "100px" }}
        isSignedIn={isLoggedIn}
      />
    </div>
  );
}

export default GoogleSignIn;
