import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "src/redux/store";
import { UserInfo, AuthProvider } from "../model";

interface AuthState {
  loggedIn: boolean;
  UserInfo: UserInfo | undefined;
}

const initialState: AuthState = {
  loggedIn: false,
  UserInfo: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthProvider>) => {
      if (action.payload.Name === "google") {
        let profileObj = action.payload.GoogleLoginResponse?.profileObj;
        if (profileObj) {
          state.loggedIn = true;
          state.UserInfo = {
            Username: profileObj.email,
            Name: profileObj.name,
            Email: profileObj.email,
            FirstName: profileObj.givenName,
            LastName: profileObj.familyName,
            ImageUrl: profileObj.imageUrl,
          };
        }
      }
    },
    logout: (state, action: PayloadAction<number>) => {
      state.loggedIn = false;
      state.UserInfo = undefined;
    },
  },
});

export const { login, logout } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLoggedIn = (state: RootState) => state.auth.loggedIn;

export default authSlice.reducer;
