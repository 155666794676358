import React from "react";
import { GoogleLogout } from "react-google-login";
import { clientId } from "./config";
import { selectLoggedIn, logout } from "./redux/slice";
import { useAppSelector, useAppDispatch } from "src/redux/hooks";

function Logout() {
  const isLoggedIn = useAppSelector(selectLoggedIn);
  const dispatch = useAppDispatch();

  const onSuccess = () => {
    dispatch(logout(0));
  };

  return (
    <div>
      <GoogleLogout
        clientId={clientId}
        buttonText="Logout"
        onLogoutSuccess={onSuccess}
      ></GoogleLogout>
    </div>
  );
}

export default Logout;
