import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import SignIn from "src/components/Auth/SignIn";
import { selectLoggedIn, login } from "src/components/Auth/redux/slice";
import { useAppSelector, useAppDispatch } from "src/redux/hooks";
import Home from "../../components/Home";
import About from "../../components/About";
import Logout from "../../components/Auth/Logout";

export default function AppLayout() {
  const isLoggedIn = useAppSelector(selectLoggedIn);
  let renderLayout = <SignIn />;
  if (isLoggedIn) {
    renderLayout = (
      <div>
        <h1>Welcome!</h1>
        <Logout />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
        </Routes>
      </div>
    );
  }
  return <div>{renderLayout}</div>;
}
